import { defineMessage } from "react-intl";

export const GUEST_GENDER_VALUE_MALE = "male";

export const GUEST_GENDER_VALUE_FEMALE = "female";

export const GUEST_GENDER_VALUE_COUPLE = "couple";

export const guestGenderMessage = defineMessage({
  defaultMessage:
    "{gender, select, male {M.} female {Mme.} couple {M. et Mme.} other {gender}}",
});
