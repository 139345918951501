// deps
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { VStack, Box, Button } from "@chakra-ui/react";
import NextLink from "next/link";

// components
import Splash from "../Splash";

// libraries
import { apiGetErrorDetail, apiGetErrorStatus } from "../../helpers/api";
import generateAdminUrl from "../../libraries/utils/generateAdminUrl";

/**
 * @typedef Props
 * @property {"view"|"viewAny"} [type]
 * @property {*} error
 */
/**
 * @param {Props} props
 */
export default function ErrorGlobal(props) {
  const { type, error } = props;

  const intl = useIntl();

  const status = apiGetErrorStatus({ error });

  const isUnauthorized = 403 === status;
  const isNotFound = 404 === status;

  return (
    <VStack>
      {isUnauthorized || isNotFound ? (
        <Splash
          title={intl.formatMessage({
            defaultMessage: "Erreur 404",
          })}
          description={intl.formatMessage({
            defaultMessage:
              "Nous sommes désolés, la page que vous avez demandé n’existe pas ou n’est plus disponible à cette adresse.",
          })}
          image="error"
        />
      ) : (
        <Splash
          title={intl.formatMessage({
            defaultMessage: "Une erreur est survenue…",
          })}
          description={
            apiGetErrorDetail({ error }) ??
            intl.formatMessage(
              {
                defaultMessage:
                  "Quelque chose s’est mal passé lors de la récupération {type, select, view {de la ressource} viewAny {des résultats} other {de/du {type}}}…",
              },
              { type },
            )
          }
          image="error"
        />
      )}

      <Box>
        <NextLink
          href={generateAdminUrl({ id: "home", includeBasePath: true })}
          passHref={true}>
          <Button variant="solid" as="a" colorScheme="brandSecondary">
            {intl.formatMessage({
              id: "raiden.admin.pages.NotFound.action.home",
              defaultMessage: "Revenir à l’accueil",
            })}
          </Button>
        </NextLink>
      </Box>
    </VStack>
  );
}

ErrorGlobal.propTypes = {
  error: PropTypes.any,
  type: PropTypes.oneOf(["view", "viewAny"]),
};
