import { defineMessage } from "react-intl";

// Type de statistique.
export const METRICS_METRIC_VALUE_ROOM_AVAILABILITY_KPI =
  "room_availability_kpi";
export const METRICS_METRIC_VALUE_ROOM_BOOKING_KPI = "room_booking_kpi";
export const METRICS_METRIC_VALUE_SUBSCRIPTION_KPI = "subscription_kpi";
export const METRICS_METRIC_VALUE_HOTEL_KPI = "hotel_kpi";

export const METRICS_METRICS = {
  [METRICS_METRIC_VALUE_ROOM_AVAILABILITY_KPI]: {
    id: METRICS_METRIC_VALUE_ROOM_AVAILABILITY_KPI,
    visible: true,
  },
  [METRICS_METRIC_VALUE_ROOM_BOOKING_KPI]: {
    id: METRICS_METRIC_VALUE_ROOM_BOOKING_KPI,
    visible: true,
  },
  [METRICS_METRIC_VALUE_SUBSCRIPTION_KPI]: {
    id: METRICS_METRIC_VALUE_SUBSCRIPTION_KPI,
    visible: false,
  },
  [METRICS_METRIC_VALUE_HOTEL_KPI]: {
    id: METRICS_METRIC_VALUE_HOTEL_KPI,
    visible: true,
  },
};

export const metricsMetricMessage = defineMessage({
  defaultMessage: `{metric, select, room_availability_kpi {disponibilités} room_booking_kpi {réservations} subscription_kpi {abonnements} hotel_kpi {visibilité} other {{metric}} }`,
});

export const METRICS_METRIC_LIST = Object.values(METRICS_METRICS);

// Contexte de la statistique.

export const METRICS_SOURCE_TYPE_VALUE_ENVIRONMENT = "environment";
export const METRICS_SOURCE_TYPE_VALUE_CUSTOMER = "customer";
export const METRICS_SOURCE_TYPE_VALUE_ROOM = "room";
export const METRICS_SOURCE_TYPE_VALUE_HOTEL = "hotel";

export const METRICS_SOURCE_TYPES = {
  [METRICS_SOURCE_TYPE_VALUE_ENVIRONMENT]: {
    id: METRICS_SOURCE_TYPE_VALUE_ENVIRONMENT,
    visible: false,
  },
  [METRICS_SOURCE_TYPE_VALUE_CUSTOMER]: {
    id: METRICS_SOURCE_TYPE_VALUE_CUSTOMER,
    visible: false,
  },
  [METRICS_SOURCE_TYPE_VALUE_ROOM]: {
    id: METRICS_SOURCE_TYPE_VALUE_ROOM,
    visible: true,
  },
  [METRICS_SOURCE_TYPE_VALUE_HOTEL]: {
    id: METRICS_SOURCE_TYPE_VALUE_HOTEL,
    visible: true,
  },
};

export const METRICS_SOURCE_TYPE_LIST = Object.values(METRICS_SOURCE_TYPES);

export const metricsSourceTypeMessage = defineMessage({
  defaultMessage: `{objectType, select, environment {environnement} customer {propriétaire} room {hébergement} hotel {structure} other {{objectType}} }`,
});

export const metricsBySourceTypeMessage = defineMessage({
  defaultMessage: `{objectType, select, environment {par environnement} customer {par propriétaire} room {par hébergement} hotel {par structure} other {{objectType}} }`,
});

export const metricsDateTypeMessage = defineMessage({
  defaultMessage: `{dateType, select, checkin {date de séjour} confirmed {date de création} other {{dateType}} }`,
});

// date type
export const METRICS_DATE_TYPE_VALUE_CHECKIN = "checkin";
export const METRICS_DATE_TYPE_VALUE_CONFIRMED = "confirmed";

export const METRICS_DATE_TYPES = {
  [METRICS_DATE_TYPE_VALUE_CHECKIN]: {
    id: METRICS_DATE_TYPE_VALUE_CHECKIN,
  },
  [METRICS_DATE_TYPE_VALUE_CONFIRMED]: {
    id: METRICS_DATE_TYPE_VALUE_CONFIRMED,
  },
};

export const METRICS_DATE_TYPE_LIST = Object.values(METRICS_DATE_TYPES);
