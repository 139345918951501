import {
  Box,
  Checkbox,
  Flex,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { createContext, forwardRef, useMemo, useContext } from "react";
import { useIntl } from "react-intl";

const LanguagePicker = forwardRef(
  /**
   * @typedef {object} Props
   * @property {Array<import("../../../types/Customer").CustomerSpokenLanguages>} languages
   * @property {string} name
   * @property {Array<string>} [value]
   * @property {(params: {target: {name: string, value: string | string[]}}) => void} onChange
   * @property {boolean} isInvalid
   * @property {boolean} isDisabled
   * @property {boolean} isRequired
   */
  /**
   * @param {Props} props
   */
  function LanguagePicker(
    { name, value, languages, onChange, isInvalid, isDisabled, isRequired },
    ref,
  ) {
    const intl = useIntl();

    const filteredValue = value ?? [];

    const color = useColorModeValue("gray.50", "gray.600");

    /**
     * @param {import("../../../types/Customer").CustomerSpokenLanguages} lang
     */
    function toggleLanguage(lang) {
      onChange({
        target: {
          name,
          value: filteredValue?.find((v) => v === lang.code)
            ? filteredValue?.filter((v) => v !== lang.code)
            : [...(filteredValue || []), lang.code],
        },
      });
    }

    const contextValue = useContext(LanguagePickerContext);

    if (!contextValue.generateSrc) {
      throw new Error(
        "LanguagePicker must be used within an LanguagePickerProvider",
      );
    }

    return (
      <Flex flexWrap="wrap" ref={ref} gap="1rem" maxWidth="100%">
        {languages.map((language, index) => (
          <Box
            key={index}
            p=".5rem"
            minWidth="8.75rem"
            borderRadius="4px"
            backgroundColor={color}>
            <Checkbox
              onChange={() => {
                toggleLanguage(language);
              }}
              isChecked={
                filteredValue?.find((v) => v === language.code) !== undefined
              }
              isInvalid={isInvalid}
              isRequired={isRequired}
              isDisabled={isDisabled}
              colorScheme="brandPrimary">
              <Flex gap=".25rem">
                {language?.img && contextValue.generateSrc && (
                  <Image
                    width="24px"
                    alt={intl.formatMessage({ defaultMessage: "Image" })}
                    src={contextValue.generateSrc(language.img)}
                  />
                )}
                {intl.formatMessage(language.name)}
              </Flex>
            </Checkbox>
          </Box>
        ))}
      </Flex>
    );
  },
);

export default LanguagePicker;

/**
 * @typedef {object} LanguagePickerContextValue
 * @property {((img: string ) =>  string) | null} generateSrc
 */

/** @type {LanguagePickerContextValue} **/
const DEFAULT_VALUES = {
  generateSrc: null,
};

export const LanguagePickerContext = createContext(DEFAULT_VALUES);

export function LanguagePickerProvider({ generateSrc, children }) {
  const value = useMemo(() => {
    return generateSrc;
  }, [generateSrc]);

  return <LanguagePickerContext.Provider value={value} children={children} />;
}
