import { defineMessage } from "react-intl";

// const représentant les langues disponibles sur l'application
export const LOCALES = ["fr", "en"];

// default locale
export const DEFAULT_LOCALE = "fr";

export const LOCALES_LANG_NAMES = {
  fr: {
    id: "fr",
    name: "🇫🇷 Français",
  },
  en: {
    id: "en",
    name: "🇬🇧 English",
  },
};

export const LOCALES_LANG_NAME_LIST = Object.values(LOCALES_LANG_NAMES);

// translate locales
export const LOCALES_LANG_TRANSLATE_NAMES = {
  fr: {
    id: "fr",
    icon: "🇫🇷",
    native_name: "français",
    name: defineMessage({
      defaultMessage: "français",
    }),
  },
  en: {
    id: "en",
    icon: "🇬🇧",
    native_name: "English",
    name: defineMessage({
      defaultMessage: "anglais",
    }),
  },
};

export const LOCALES_LANG_TRANSLATE_NAME_LIST = Object.values(
  LOCALES_LANG_TRANSLATE_NAMES,
);

/** @type {Array<import("../types/Customer").CustomerSpokenLanguages>} */
export const CUSTOMER_SPOKEN_LANGUAGES = [
  {
    code: "en",
    name: defineMessage({ defaultMessage: "Anglais" }),
    img: "illustrations/languages/gb.svg",
  },
  {
    code: "de",
    name: defineMessage({ defaultMessage: "Allemand" }),
    img: "illustrations/languages/de.svg",
  },
  {
    code: "ru",
    name: defineMessage({ defaultMessage: "Russe" }),
    img: "illustrations/languages/ru.svg",
  },
  {
    code: "es",
    name: defineMessage({ defaultMessage: "Espagnol" }),
    img: "illustrations/languages/es.svg",
  },
  {
    code: "it",
    name: defineMessage({ defaultMessage: "Italien" }),
    img: "illustrations/languages/it.svg",
  },
  {
    code: "nl",
    name: defineMessage({ defaultMessage: "Néerlandais" }),
    img: "illustrations/languages/nl.svg",
  },
  {
    code: "pt",
    name: defineMessage({ defaultMessage: "Portugais" }),
    img: "illustrations/languages/pt.svg",
  },
  {
    code: "jp",
    name: defineMessage({ defaultMessage: "Japonais" }),
    img: "illustrations/languages/jp.svg",
  },
  {
    code: "sgn",
    name: defineMessage({ defaultMessage: "Signes" }),
    img: "illustrations/languages/sign-language.svg",
  },
];
