// deps
import { defineMessage } from "react-intl";

export const guestsGenderTitleMessage = defineMessage({
  defaultMessage: `{gender, select,
    couple {M. et Mme}
    male {M.}
    female {Mme}
    couple_or {M. ou Mme}
    unknown {Non défini}
    other {}
  }`,
});

// Genres.
export const GUESTS_GENDER_VALUE_MALE = "male";
export const GUESTS_GENDER_VALUE_FEMALE = "female";
export const GUESTS_GENDER_VALUE_UNKNOWN = "unknown";
export const GUESTS_GENDER_VALUE_COUPLE = "couple";
export const GUESTS_GENDER_VALUE_COUPLE_OR = "couple_or";

export const GUESTS_GENDERS = {
  [GUESTS_GENDER_VALUE_MALE]: {
    id: GUESTS_GENDER_VALUE_MALE,
  },
  [GUESTS_GENDER_VALUE_FEMALE]: {
    id: GUESTS_GENDER_VALUE_FEMALE,
  },
  [GUESTS_GENDER_VALUE_COUPLE]: {
    id: GUESTS_GENDER_VALUE_COUPLE,
  },
  [GUESTS_GENDER_VALUE_COUPLE_OR]: {
    id: GUESTS_GENDER_VALUE_COUPLE_OR,
  },
  [GUESTS_GENDER_VALUE_UNKNOWN]: {
    id: GUESTS_GENDER_VALUE_UNKNOWN,
  },
};

export const GUESTS_GENDER_LIST = Object.values(GUESTS_GENDERS);
